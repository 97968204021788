/**
 * Pass an array with [url, session] to use supabase authentication in the SWR request
 *
 * @param args
 * @returns {object}
 */
export default async function Fetcher(...args) {
  let res;
  const options = {} as Record<string, {}>;
  const firstArg = args[0];

  // Pass an array with [url, session] to use supabase authentication in the SWR request
  if (Array.isArray(firstArg)) {
    const [url, session] = firstArg;
    if (!url) {
      return null;
    }
    if (session) {
      options.headers = {
        supabase_access_token: session.access_token,
        supabase_refresh_token: session.refresh_token
      };
    }
    res = await fetch(url, options);
  } else {
    // @ts-ignore
    res = await fetch(...args, options);
  }

  return res.json();
}
