import { MDXProvider } from "@mdx-js/react";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { ThemeProvider } from "next-themes";
import Head from "next/head";
import MDXComponents from "~/components/MDXComponents";
import { supabase } from "~/lib/supabase";
import "~/styles/global.scss";
// import { useAnalytics } from '@/lib/analytics';

export default function App({ Component, pageProps }) {
  // useAnalytics();

  return (
    <SessionContextProvider supabaseClient={supabase} initialSession={pageProps.initialSession}>
      <ThemeProvider attribute="class">
        <MDXProvider components={MDXComponents}>
          <Head>
            <meta content="width=device-width, initial-scale=1" name="viewport" />
            {process.env.NODE_ENV === "production" && (
              <script
                defer
                src="https://analytics.sambatista.com/script.js"
                data-website-id="7978aca5-8a71-4d77-aa80-ca249bc8aef0"
              ></script>
            )}
          </Head>
          <Component {...pageProps} />
        </MDXProvider>
      </ThemeProvider>
    </SessionContextProvider>
  );
}
